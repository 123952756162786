import React from 'react';
import NumberFormat from 'react-number-format';
import { Flex, Box, Image, Text, Link } from 'rebass/styled-components';

const formatLink = (link) => {
  const formattedLink = link.includes('https://') ? link : `https://${link}`;
  return formattedLink;
};

const MainInfo = ({ listing, logo, handleLoadError }) => {
  return (
    <Flex flexDirection={['column', 'row', 'row']} marginBottom={[6, null]} pr={1}>
      <Flex flexDirection="column" width={256} justifyContent="start" height={[256]} mr={5} alignItems="center">
        <Image src={logo} onError={handleLoadError} alt="" />
      </Flex>
      <Flex flexDirection="column" pr={1}>
        <Text variant="heading2">{listing.name}</Text>
        {listing.hideAddress === false ? (
          <div>
            <Text variant="heading3">{`Street address: ${listing.address}${
              listing.addressExtra ? `, ${listing.addressExtra}` : ''
            }`}</Text>
            <Text variant="heading3">{`${listing.city}, ${listing.state} ${listing.zip}`}</Text>
          </div>
        ) : null}
        <div>
          <Text variant="heading3">
            Phone:
            {listing.phones && listing.phones.length ? (
              <NumberFormat value={listing.phones[0].number} format=" (###) ###-####" displayType="text" />
            ) : (
              'None'
            )}
          </Text>
        </div>
        {listing.urls && listing.urls.length > 0 ? (
          <Flex flexDirection="row">
            <Text variant="heading3" sx={{ minWidth: 'auto' }} mr={1}>
              Website:{' '}
            </Text>
            <Text variant="anchor">
              <Link
                sx={{
                  textDecoration: 'none',
                  color: 'primary',
                }}
                href={listing.urls && listing.urls.length ? formatLink(listing.urls[0].url) : '#'}
                rel="nofollow"
                target="_blank"
              >
                {
                  <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                    {`${listing.urls && listing.urls.length ? listing.urls[0].url : 'None'}`}
                  </div>
                }
              </Link>
            </Text>
          </Flex>
        ) : (
          ''
        )}
        {listing.emails && listing.emails.length > 0 && (
          <Text variant="heading3">{`Email: ${listing.emails[0].email}`}</Text>
        )}
        <Flex flexDirection="row" width={2 / 5} sx={{ height: '35px', maxWidth: '176px' }}>
          {listing.socialProfiles && listing.socialProfiles.length > 0
            ? listing.socialProfiles.map((item, ind) => {
                if (item.type === 'FACEBOOK') {
                  return (
                    <a href={item.url} key={ind} target="_blank" rel="nofollow">
                      <Image
                        src="https://img.icons8.com/material/96/000000/facebook--v1.png"
                        key={ind}
                        alt=""
                        mx={0}
                        sx={{ height: '35px', maxWidth: '35px' }}
                        onError={handleLoadError}
                      />
                    </a>
                  );
                }
                if (item.type === 'GOOGLEPLUS') {
                  return (
                    <a href={item.url} key={ind} target="_blank" rel="nofollow">
                      <Image
                        src="https://img.icons8.com/material/96/000000/google-plus--v1.png"
                        key={ind}
                        alt=""
                        mx={0}
                        sx={{ height: '35px', maxWidth: '35px' }}
                        onError={handleLoadError}
                      />
                    </a>
                  );
                }
                if (item.type === 'LINKEDIN') {
                  return (
                    <a href={item.url} key={ind} target="_blank" rel="nofollow">
                      <Image
                        src="https://img.icons8.com/material/96/000000/linkedin--v1.png"
                        key={ind}
                        alt=""
                        mx={0}
                        sx={{ height: '35px', maxWidth: '35px' }}
                        onError={handleLoadError}
                      />
                    </a>
                  );
                }
                if (item.type === 'TWITTER') {
                  return (
                    <a href={item.url} key={ind} target="_blank" rel="nofollow">
                      <Image
                        src="https://img.icons8.com/material/96/000000/twitter--v1.png"
                        key={ind}
                        alt=""
                        mx={0}
                        sx={{ height: '35px', maxWidth: '35px' }}
                        onError={handleLoadError}
                      />
                    </a>
                  );
                }
                return '';
              })
            : ''}
        </Flex>
      </Flex>
    </Flex>
  );
};
export default MainInfo;
